<template>
    <div>
        <load-screen-component :is-loading="isLoading"></load-screen-component>
        <page-header pageTitle="PANEL.INDEX_TITLE" :btnNew="'Visualizar Planos'" :iconBtnNew="'icon-eye1 font-15'"
                     have-search @searching="setFilter"
                     @newType="this.$router.push('/planos')"/>
        <div v-if="items?.data" class="card">
            <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
                <div class="row space-between mt-2" style="margin-left: -22px;">
                    <div @click="open(iten)" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div class="list-primary hide-text"> {{ iten.company_name }}</div>
                    </div>
                    <div @click="open(iten)" class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ml-negative-1">
                        <div class="color-grey">
                            <a class="button-center">
                                <i class="icon-keyboard_arrow_down pointer" v-if="!iten.open"></i>
                                <i class="icon-keyboard_arrow_up pointer" v-if="iten.open"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-1 mr-1 w-auto">
                        <div class="text-right">
                            <a type="button" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false"
                               style="font-size: 20px" id="listDropdown">
                                <i class="icon-more_vert" title="Opções"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-black" style="width: max-content">
                                <div class="dropdown-item pointer text-white"
                                     @click="setModalAllotments(iten.company_id)">
                                    <i class="icon-eye1 font-15"/>{{ t('PANEL.INFORM_DB_CONNECTION') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="iten.open">
                        <div class="table-settings border-top2 detail-shadow-t">
                            <div class="detail mt-3">
                                <div class="d-flex flex-column align-items-center w-100">
                                    <div class="row gutters w-100 mb-3">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                            <div class="form-group" style="width: 100%">
                                                <label :for="'plan' + key">{{ t('PANEL.PLAN.PLANS') }} </label>
                                                <select2 :id="'plan' + key" :options="plans" v-model="iten.plan.select"
                                                         :settings="{'width' : '100%'}"
                                                         @select="alterPlan(iten.plan.select, key)"
                                                         :placeholder="iten.plan.name"></select2>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                            <div class="form-group" style="width: 100%">
                                                <label for="token_w">{{ t('PANEL.WHATSAPP_TOKEN') }} </label>
                                                <input type="text" id="token_w" class="form-control"
                                                       placeholder="WhatsApp Token"
                                                       v-model="iten.whatsapp_token">
                                            </div>
                                        </div>
                                        <label class="mt-1 ml-2 col-12">{{ t('PANEL.CONFIG_PLAN') }}:</label>
                                        <div
                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row gutters card-body border border-secondary rounded mr-1 ml-1">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="form-group">
                                                    <label id="labelMaxLots" for="maxLots">{{
                                                            t('PANEL.PLAN.MAX_LOTS_PERMITED')
                                                        }} </label>
                                                    <input type="text" id="maxLots" class="form-control"
                                                           v-model="iten.plan.max_lots_permitted" disabled>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="form-group">
                                                    <label id="labelEmailNotifications"
                                                           for="emailNotifications">{{
                                                            t('PANEL.PLAN.EMAIL_NOTIFICATIOS')
                                                        }} </label>
                                                    <input type="text" id="emailNotifications" class="form-control"
                                                           v-model="iten.plan.email_notifications" disabled>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="form-group">
                                                    <label id="labelWppNotifications"
                                                           for="WppNotifications">{{
                                                            t('PANEL.PLAN.WHATSAPP_NOTIFICATIONS') + ': '
                                                        }} </label>
                                                    <input type="text" id="WppNotifications" class="form-control"
                                                           v-model="iten.plan.whatsapp_notifications" disabled>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="form-group">
                                                    <label id="labelStoreDrive" for="storeDrive">{{
                                                            t('PANEL.PLAN.MAX_SIZE_DRIVE') + ': '
                                                        }} </label>
                                                    <input type="text" id="storeDrive" class="form-control"
                                                           v-model.lazy="iten.plan.size_store_drive"
                                                           v-money3="sizeDriveConfig" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" style="margin-left: 2rem">
                                        <button data-toggle="modal" data-target="#customModalTwo"
                                                @click="updateCompany(iten, key)"
                                                class="p-1 rounded-pill btn-primary font-weight-bold"
                                                style="width: 6rem;">Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="card" v-if="items && items.validations || !items?.data">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NONE_PANEL_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <modal-add-id-configd
                v-if="modalChosen == 1"
                @close="closeModalAdd"
                :allotments-index="allotments"
        ></modal-add-id-configd>
        <pagination-component v-if="items && items.data && items.data[0]" :items="items"
                              :to="'/painel-administrativo/1829934b-ecfc-405c-9811-d5549697887e'"
                              @changePage="index(filter, $event)"></pagination-component>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import PageHeader from "@/components/layouts/pageHeader";
import LoadScreenComponent from "@/components/layouts/loadScreenComponent";
import Panel from "@/services/Panel";
import Plan from "@/services/Plan";
import Select2 from "vue3-select2-component";
import PaginationComponent from "@/components/layouts/PaginationComponent";
import 'vue-select/dist/vue-select.css';
import Validate from "@/components/validate";
import Allotments from '../../services/Allotments';
import modalAddIdConfigd from './modalAddIdConfigd';
import {Money3Directive} from 'v-money3';
import jwt_decode from 'jwt-decode';
import {mapState} from 'vuex';

export default {
    name: "indexComponent",
    mixins: [Validate],
    directives: {money3: Money3Directive},
    components: {
        PageHeader,
        LoadScreenComponent,
        PaginationComponent,
        Select2,
        modalAddIdConfigd,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            isLoading: 'isLoading'
        })
    },
    data() {
        return {
            items: null,
            plans: [],
            allotments: [],
            modalChosen: null,
            filter: null,
            invalidQtdMaxB: null,
            invalidQtdMov: null,
            amountMaxBillConfig: {
                min: 0.00,
                prefix: 'R$ ',
                masked: true,
                decimal: ',',
                precision: 2,
                disabled: false,
                thousands: '.',
                allowBlank: false,
                disableNegative: true,
            },
            amountMinBillConfig: {
                min: 0.00,
                prefix: 'R$ ',
                masked: true,
                decimal: ',',
                precision: 2,
                disabled: false,
                thousands: '.',
                allowBlank: false,
                disableNegative: true,
            },
            sizeDriveConfig: {
                min: 0.00,
                suffix: ' GB',
                masked: true,
                decimal: '.',
                precision: 2,
                disabled: false,
                thousands: '',
                allowBlank: false,
                disableNegative: true,
            },
        }
    },
    mounted() {
        if (!this.hasPermissionShowPanel()) {
            this.$router.push({name: 'Home'});
        }
        this.index();
        this.planIndex();
    },
    methods: {
        hasPermissionShowPanel() {
            let token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },
        setFilter(term) {
            this.filter = term;
            this.index(this.filter);
        },
        closeModalAdd() {
            this.modalChosen = null;
        },
        index(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);
            Panel.index(filter, page, true).then((res) => {
                this.items = res.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.items = {};
                this.$store.commit('changeLoading', false);
            });
        },
        setModalAllotments(companyId) {
            this.$store.commit('changeLoading', true);
            Allotments.listByCompany(companyId).then(resp => {
                this.allotments = resp.data;
                this.modalChosen = 1;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        open(iten) {
            iten.open = !iten.open ? true : false;
        },
        updateCompany(data) {
            this.$store.commit('changeLoading', true);
            this.items = null;
            Panel.update(data).then(async () => {
                this.$store.commit('changeLoading', true);
                this.index(this.filter);
            }).catch(async (err) => {
                await this.index(this.filter);
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            })
        },
        planIndex() {
            Plan.listAll().then((res) => {
                this.plans = res.data;
            })
        },
        alterPlan(id, key) {
            this.$store.commit('changeLoading', true);
            Plan.show(id).then((res) => {
                this.items.data[key].plan = res.data;
                this.items.data[key].plan.select = id;
            }).finally(() => this.$store.commit('changeLoading', false));
        },
    }
}
</script>

<style scoped>
.button-center {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #333333;
    border-radius: 50px;
    font-size: 160%;
}

.table-settings {
    padding-top: 0.6rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.1rem !important;
    background-color: white !important;
}

.fade-enter-active, .fade-leave-active {
    -webkit-transition: .1s ease-out;
    -moz-transition: .1s ease-out;
    transition: .1s ease-out;
}

.fade-enter, .fade-leave-to {
    -webkit-transition: .1s ease-out;
    -moz-transition: .1s ease-out;
    transition: .1s ease-out;
}

.space-between {
    padding-bottom: 8px;
    align-items: center;
    justify-content: space-between;
}

.font-seven {
    font-size: 0.7rem !important;
}

.detail {
    display: flex;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    justify-content: space-between;
}

.dropdown-menu-custom {
    right: 0px !important;
    left: 13px !important;
    width: 7rem !important;
    min-width: 7rem !important;
}

.ml-negative-1 {
    margin-left: -3.5%;
    padding-left: 0px
}
</style>